import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Headings/Title"
import { marked } from "marked"
import styled from "styled-components"
import ReactToPrint, { PrintContextConsumer } from "react-to-print"
import { FaPrint } from "react-icons/fa"

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const renderer = new marked.Renderer()

const copyToClipboard = str => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str).then(
      function () {
        console.log("Copying to clipboard was successful!")
      },
      function (err) {
        console.error("Could not copy text: ", err)
      }
    )
  } else if (window.clipboardData) {
    // Internet Explorer
    window.clipboardData.setData("Text", str)
  }
}

class MDXLive extends React.Component {
  constructor(props) {
    super(props)
    this.editorScroll = React.createRef()
    this.previewScroll = React.createRef()
    this._preventEvent = false
    this.state = {
      markdown: placeholder,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.setState({
      markdown: e.target.value,
    })
  }

  onEditorScroll = e => {
    if (this._preventEvent) {
      this._preventEvent = false
      return
    }

    this._preventEvent = true
    this.previewScroll.current.scrollTop = e.target.scrollTop
  }

  onPreviewScroll = e => {
    if (this._preventEvent) {
      this._preventEvent = false
      return
    }

    this._preventEvent = true
    this.editorScroll.current.scrollTop = e.target.scrollTop
  }

  render() {
    return (
      <Layout>
        <Seo title="React Live" />
        <section className="react-live-section">
          <Title>MDX Live</Title>
          <div className="container-fluid py-5">
            <Pagination>
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <h5 className="card-header text-center fw-bold">
                  Editor
                  <button
                    className="btn btn-outline-primary btn-sm mx-2"
                    onClick={() => {
                      copyToClipboard(this.state.markdown)
                    }}
                  >
                    Copy
                  </button>
                </h5>

                <textarea
                  className="editor-scroll mdx-editor p-3"
                  style={{
                    minWidth: "100%",
                  }}
                  id="editor"
                  value={this.state.markdown}
                  onChange={this.handleChange}
                  type="text"
                  ref={this.editorScroll}
                  onScroll={this.onEditorScroll}
                />
              </div>
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <h5 className="card-header text-center fw-bold">
                  Preview
                  <ReactToPrint content={() => this.componentRef}>
                    <PrintContextConsumer>
                      {({ handlePrint }) => (
                        <button
                          className="btn btn-outline-primary btn-sm mx-2"
                          onClick={handlePrint}
                        >
                          {" "}
                          <FaPrint /> Print this Preview
                        </button>
                      )}
                    </PrintContextConsumer>
                  </ReactToPrint>
                </h5>
                <div
                  ref={this.previewScroll}
                  className="preview-scroll"
                  onScroll={this.onPreviewScroll}
                >
                  <div
                    ref={el => (this.componentRef = el)}
                    style={{ minWidth: "100%" }}
                    className="card-body"
                    dangerouslySetInnerHTML={{
                      __html: marked(this.state.markdown, {
                        renderer: renderer,
                      }),
                    }}
                  />
                </div>
              </div>
            </Pagination>
          </div>
        </section>
      </Layout>
    )
  }
}

const placeholder = `

Links
-----

- You can insert links in text like [this](/tutorial.md)

- You may add a [title](https://agea.github.io/tutorial.md "Markdown Tutorial") to your link (can you see the tooltip?)

- If your link <ghghhhgh> contains spaces you have to write the [link](<http://example.com/a space>) between 

- You can use spaces and markup inside the [link **text**](https://agea.github.io/tutorial.md)

- Long links may decrease source readability, so it's posible to define all links somewhere in the document (the end is a good place) and just reference the [link][tutorial.md], you may also collapse the reference if it matches the link text (example:  [tutorial.md][])

- You may also write directly the link: <https://agea.github.io/tutorial.md>

- It will work also for email addresses: <email@example.com> (you may write vaild email links also using [mailto](mailto:email@example.com) as protocol)


[tutorial.md]: https://agea.github.io/tutorial.md

`

export default MDXLive
